import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { StaticImage } from 'gatsby-plugin-image';

import { container, prize, title, knight } from './akcja-gorzka-thank-you-page.module.scss';

import { IPage } from '../models/page.model';

import AkcjaGorzkaLayout from '../layouts/akcja-gorzka-layout';
import AkcjaGorzkaPrize from '../components/organisms/akcja-gorzka-prize';

interface IAkcjaGorzkaThankYouPageProps {
    readonly data: {
        page: IPage | null;
    };
}

const knightImg = '../../src/assets/images/akcja-gorzka/knight.png';

const AkcjaGorzkaThankYouPage: React.FC<IAkcjaGorzkaThankYouPageProps> = ({ data }) => {
    const { t } = useI18next();
    const { page } = data;
    const from =
        typeof window !== 'undefined'
            ? new URLSearchParams(window.location.search).get('from')
            : '';

    return (
        <AkcjaGorzkaLayout
            page={page}
            className={container}
            headerProps={
                from === 'winner'
                    ? {
                          titleSecondLine: t('akcja.gorzka.thank.title.winner'),
                          subtitle: t('akcja.gorzka.thank.subtitle.winner'),
                      }
                    : {
                          titleFirstLine: t('akcja.gorzka.thank.title.1'),
                          titleSecondLine: t('akcja.gorzka.thank.title.2'),
                      }
            }
            showSideImages={true}
        >
            <p className={title}>{t('akcja.gorzka.title')}</p>
            <AkcjaGorzkaPrize className={prize} />
            <StaticImage src={knightImg} alt={''} className={knight} />
        </AkcjaGorzkaLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default AkcjaGorzkaThankYouPage;
